import { centerOf } from '@/helpers';
import { Box } from '@mui/material';
import {
  AIReport,
  ChapterCover,
  TableRenderer
} from '@survey/report/components';

type IProps = TopicItemResponse & {
  index?: string;
  cover?: string;
  tables?: TableResponseType[];
  aiReports?: SimpleAIReportResponse[];
  options: TopicOptionResponse[];
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  section?: string;
  charts?: ChartResponse[];
  single?: boolean;
};

const ReportItem = ({
  id,
  index,
  cover,
  name,
  tables,
  aiReports,
  options,
  section,
  relations,
  familiarity,
  charts,
  single
}: IProps) => {
  const aiReport = aiReports?.find((x) => x.itemId === id);

  return (
    <Box id={id.replace(/[-_]/g, '')} key={`item_${id}`}>
      <ChapterCover
        image={cover}
        index={index}
        name={name}
        section={section}
        single={single}
      />
      <Box
        p={{
          xs: 8 / 12,
          sm: centerOf(8 / 12, 4),
          md: 4
        }}
        pb={{
          xs: 2,
          sm: 3,
          md: 4
        }}
      >
        {tables &&
          tables.map((x) => (
            <TableRenderer
              key={`item_${id}`}
              id={`item_${id}`}
              table={x}
              options={options}
              relations={relations}
              familiarity={familiarity}
            />
          ))}
        {aiReport && (
          <AIReport
            title={single ? undefined : name}
            content={aiReport.content}
            charts={charts}
          />
        )}
      </Box>
    </Box>
  );
};

export default ReportItem;
