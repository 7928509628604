import { useTranslation } from '@/components';
import { useUserContext } from '@/contexts/user-context';
import { fontWeights, webClient } from '@/helpers';
import { Typography } from '@mui/material';
import { ToDo } from '@userRoot/components';
import { useEffect, useState } from 'react';

const Home = () => {
  const { setCurrentPage, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace');

  const [toDos, setToDos] = useState<IToDo[]>([]);

  const loadToDos = async () => {
    const res = await webClient.api.surveys.list(
      {
        limit: 1
      },
      {
        loadingHandled: true
      }
    );

    setToDos(
      res.data.data.map((x) => ({
        id: x.id,
        name: x.name,
        startDate: x.createdAt,
        neededResponses: x.neededResponses - x.collectedResponses,
        hasDiagnose: x.hasDiagnose
      }))
    );
  };

  useEffect(() => {
    setHeader();
    setCurrentPage('home', 'Feed');

    loadToDos();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {toDos.length > 0 && (
        <>
          <Typography
            fontWeight={fontWeights.extraBold}
            fontSize={20}
            mb={16 / 12}
          >
            {t('RecentAssessments')}
          </Typography>
          {toDos.map((x, i) => (
            <ToDo key={`todo${i}`} {...x} />
          ))}
        </>
      )}
    </>
  );
};

export default Home;
