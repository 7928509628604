import { useTranslation } from '@/components';
import { addAlphaToHex, fontWeights, getAuthCookie } from '@/helpers';
import {
  FileAttachment04,
  FileDownload02,
  FileMinus02,
  FileSearch02,
  Settings02,
  User03
} from '@/icons';
import { useAppContext } from '@contexts/app-context';
import UserContext from '@contexts/user-context';
import { Logout, Menu, MenuOpen } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme
} from '@mui/material';
import NotFound from '@root/not-found';
import Assessments from '@user/assessments';
import AssessmentItem from '@user/assessments/item';
import Diagnoses from '@user/diagnoses';
import Home from '@user/home';
import Reports from '@user/reports';
import Settings, {
  SettingsDelete,
  SettingsExport,
  SettingsPassword
} from '@user/settings';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import { Link } from 'react-router-dom';

interface SideItem {
  name: string;
  label: string;
  to: string;
  icon: ReactNode;
}

const sideItems: SideItem[] = [
  {
    name: 'home',
    label: 'ToDo',
    to: '/user/',
    icon: <FileDownload02 />
  },
  {
    name: 'incompleteAssessments',
    label: 'IncompleteAssessments',
    to: '/user/assessments/',
    icon: <FileMinus02 />
  },
  {
    name: 'reports',
    label: 'Your360Reports',
    to: '/user/reports/',
    icon: <FileAttachment04 />
  },
  {
    name: 'diagnoses',
    label: 'AnalysisOfChallenges',
    to: '/user/diagnoses/',
    icon: <FileSearch02 />
  },
  {
    name: 'settings',
    label: 'SettingsPrivacy',
    to: '/user/settings/',
    icon: <Settings02 />
  }
];

const UserRouter = () => {
  const { setMainClasses, ...context } = useAppContext();
  const theme = useTheme();
  const t = useTranslation('PersonalSpace');

  const [open, setOpen] = useState(true);
  const [currentPage, setCurrentPageState] = useState({
    page: 'home',
    title: 'Profile'
  });
  const [drawerVariant, setDrawerVariant] = useState<
    'persistent' | 'temporary'
  >('persistent');

  const setCurrentPage = (page: string, title: string) => {
    setCurrentPageState({
      page,
      title
    });
  };

  const setHeader = (back?: string | ReactNode) => {
    context.setHeader('/user/', back, [
      <Tooltip title='Sign out'>
        <IconButton component={Link} to='/sign-out'>
          <Logout />
        </IconButton>
      </Tooltip>
    ]);
  };

  const onResize = useCallback(() => {
    if (
      window.innerWidth < theme.breakpoints.values.sm &&
      drawerVariant !== 'temporary'
    ) {
      setDrawerVariant('temporary');
      setOpen(false);
    } else if (
      window.innerWidth >= theme.breakpoints.values.sm &&
      drawerVariant !== 'persistent'
    ) {
      setDrawerVariant('persistent');
      setOpen(true);
    }
  }, [drawerVariant]);

  useEffect(() => {
    setMainClasses();
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return (
    <UserContext.Provider
      value={{
        ...context,
        drawerVariant,
        setCurrentPage,
        setHeader,
        setMainClasses
      }}
    >
      <Box display='flex' flexDirection='row' overflow='hidden' mx={-2}>
        <Drawer
          open={drawerVariant === 'persistent' || open}
          onClose={() => setOpen(false)}
          variant={drawerVariant}
          anchor='left'
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor:
                drawerVariant === 'temporary'
                  ? addAlphaToHex('#fff', 70)
                  : 'transparent',
              transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen
              })
              // ...(!open && {
              //   overflowX: 'hidden',
              //   transition: theme.transitions.create('width', {
              //     easing: theme.transitions.easing.sharp,
              //     duration: theme.transitions.duration.leavingScreen
              //   }),
              //   width: theme.spacing(7),
              //   [theme.breakpoints.up('sm')]: {
              //     width: theme.spacing(9)
              //   }
              // })
            }
          }}
        >
          <Box
            display='flex'
            alignItems='stretch'
            justifyContent='flex-end'
            flexDirection='column'
          >
            {drawerVariant === 'temporary' && (
              <IconButton
                type='button'
                onClick={() => setOpen((x) => !x)}
                color='secondary'
                sx={{
                  alignSelf: 'flex-end',
                  mr: 1,
                  mt: 1,
                  mb: -1
                }}
              >
                <MenuOpen fontSize='large' />
              </IconButton>
            )}
            <Box
              display='flex'
              alignItems='center'
              ml={3.2}
              color={theme.palette.primary.main}
            >
              <User03 />
              <Box ml={8 / 12} fontSize={20} fontWeight={fontWeights.medium}>
                {t('$HiUser', {
                  name: getAuthCookie()?.name
                })}
              </Box>
            </Box>
            <List>
              {sideItems.map((x) => (
                <ListItem key={x.name}>
                  <ListItemButton
                    onClick={() => setOpen(false)}
                    component={Link}
                    selected={currentPage.page === x.name}
                    to={x.to}
                    sx={{
                      padding: '0 20px',
                      width: '100%',
                      textAlign: 'left',
                      flex: 'auto 0 0',
                      bgcolor: 'white',
                      boxShadow: `0 0 10px 2px ${addAlphaToHex('#000', 10)}`,
                      borderRadius: '24px',
                      transition: 'all 0.15s ease-in',
                      '&.Mui-selected': {
                        bgcolor: 'white',
                        boxShadow: `0 0 10px 2px ${addAlphaToHex(
                          theme.palette.primary.main,
                          60
                        )}`
                      }
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 32
                      }}
                    >
                      {x.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        my: 1
                      }}
                      primary={t(x.label)}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box
          flex='auto 1 1'
          overflow='hidden auto'
          p={1}
          pt={{
            xs: 0,
            sm: 1
          }}
        >
          {drawerVariant === 'temporary' && (
            <IconButton
              type='button'
              color='secondary'
              onClick={() => setOpen((x) => !x)}
            >
              <Menu fontSize='large' />
            </IconButton>
          )}
          <Routes>
            <Route path='/' index element={<Home />} />
            <Route path='/assessments' index element={<Assessments />} />
            <Route path='/assessments/:id' index element={<AssessmentItem />} />
            <Route path='/diagnoses' index element={<Diagnoses />} />
            <Route path='/reports' index element={<Reports />} />
            <Route path='/settings' index element={<Settings />} />
            <Route path='/settings/delete' index element={<SettingsDelete />} />
            {/* <Route path='/settings/email' index element={<SettingsEmail />} /> */}
            <Route path='/settings/export' index element={<SettingsExport />} />
            {/* <Route path='/settings/info' index element={<SettingsInfo />} /> */}
            <Route
              path='/settings/password'
              index
              element={<SettingsPassword />}
            />
            {/* <Route path='/settings/phone' index element={<Settings />} /> */}
            {/* <Route path='/settings/plan' index element={<SettingsPlan />} /> */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </UserContext.Provider>
  );
};

export default UserRouter;
