import { Loading, useTranslation } from '@/components';
import { ProjectStatus } from '@/enums';
import { webClient } from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { Empty, ReportCard, ReportCardGrid } from '@userRoot/components';
import { useEffect, useState } from 'react';

const Assessments = () => {
  const { setCurrentPage, showToast, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace');

  const [surveys, setSurveys] = useState<SurveyResponse[]>([]);
  const [surveysTotalCount, setSurveysTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const loadMore = async () => {
    setLoading(true);
    try {
      const res = await webClient.api.surveys.list(
        {
          afterId:
            surveys.length === 0 ? undefined : surveys[surveys.length - 1].id
        },
        {
          loadingHandled: true
        }
      );

      setSurveysTotalCount((x) => Math.max(x, res.data.totalCount));
      setSurveys((x) => [...x, ...res.data.data]);
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setHeader('/user/');
    setCurrentPage('incompleteAssessments', 'Incomplete assessments');
    loadMore();
    // eslint-disable-next-line
  }, []);

  if (loading && surveys.length === 0) {
    return <Loading />;
  }

  if (surveys && surveys.length > 0) {
    return (
      <Box>
        <Grid container spacing={16 / 12} p={16 / 12}>
          {surveys.map((x, i) => (
            <ReportCardGrid key={i}>
              <ReportCard
                to={
                  !x.projectStatus ||
                  x.projectStatus === ProjectStatus.PUBLISHED
                    ? `/user/assessments/${x.id}/`
                    : ''
                }
                buttonText='AccessPersonalInvitation'
                title={x.createdAt.asDateTime('dd MMMM yyyy')}
                children={[
                  {
                    title: '$TopicName',
                    content: x.name
                  },
                  {
                    title: '$StartDate',
                    content: x.createdAt.asDateTime('dd MMMM yyyy')
                  },
                  {
                    title: '$Status',
                    content: t(
                      x.collectedResponses < x.neededResponses
                        ? 'Incomplete'
                        : 'Completed'
                    )
                  },
                  {
                    title: 'CollectedResponses',
                    content: x.collectedResponses
                  },
                  {
                    title: 'NeededResponses',
                    content: x.neededResponses
                  }
                ]}
              />
            </ReportCardGrid>
          ))}
        </Grid>
        {loading && <Loading />}
        {surveys.length < surveysTotalCount && (
          <Box textAlign='center'>
            <Button
              variant='contained'
              type='button'
              disabled={loading}
              onClick={loadMore}
            >
              {loading ? <CircularProgress /> : t('$LoadMore')}
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Empty title={t('ThereAreNoAssessments')} subtitle={t('AssessmentsHint')} />
  );
};

export default Assessments;
