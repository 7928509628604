import { useTranslation } from '@/components';
import { useReportContext } from '@/contexts/report-context';
import {
  ChartDataType,
  TableDataType,
  TopicItemTableType,
  TopicTableType
} from '@/enums';
import {
  addAlphaToHex,
  centerOf,
  getCdnUrl,
  lightenHexColor,
  randPerm
} from '@/helpers';
import { ChatBubble } from '@mui/icons-material';
import { Box, ThemeProvider, useTheme } from '@mui/material';
import {
  AIReport,
  ChapterCover,
  CommentBox,
  Contents,
  DetailedContents,
  ReportItem,
  SectionCover,
  TableRenderer,
  TableReportHeader
} from '@survey/report/components';

const SurveyReportTable = () => {
  const theme = useTheme();

  const {
    aiReports,
    sections,
    items,
    relations,
    familiarity,
    options,
    commentSections,
    nickname,
    charts,
    summary,
    date,
    title,
    customizations
  } = useReportContext();
  let { tables, sectionReports, comments } = useReportContext();

  const summaryTables = tables
    .where((x) => x.dataType === TableDataType.SUMMARY)
    .flatMap((x) => x.tables);

  const sectionsTables = tables
    .where((x) => x.dataType === TableDataType.SECTION)
    .map((x) => ({
      id: x.dataId!,
      tables: tables
        .where(
          (y) => y.dataId === x.dataId && y.dataType === TableDataType.SECTION
        )
        .flatMap((y) => y.tables)
    }));

  const fixedComments = comments
    .flatMap((x) =>
      x.comments.map((y) => ({
        ...y,
        relationId: x.relationId
      }))
    )
    .groupBy((x) => x.commentId);

  tables = tables.map((a) => ({
    ...a,
    tables: a.tables.map((x) => {
      switch (x.type) {
        case TopicItemTableType.DETAILED:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!,
            relation: relations.find((a) => a.id === z.relationId)
          }));
          break;

        case TopicItemTableType.AVERAGE:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            relation:
              (z.relationId &&
                relations &&
                relations.find((a) => a.id === z.relationId)) ||
              undefined
          }));
          break;

        case TopicItemTableType.ANONYMOUS:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!
          }));
          break;

        case TopicItemTableType.FAMILIARITY:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!,
            familiarity:
              (z.familiarityId &&
                familiarity &&
                familiarity.options.find((a) => a.id === z.familiarityId)) ||
              undefined
          }));
          break;

        case TopicTableType.OVERALL:
        case TopicTableType.OVERALL_FAMILIARITY:
          x.item = items.find((y) => y.id === x.itemId)!;
          break;

        default:
          throw new Error('Type is not supported');
      }

      return x;
    })
  }));

  sectionReports = sectionReports?.map((x) => ({
    ...x,
    section: sections?.find((y) => y.id === x.sectionId)!
  }));

  comments = comments.map((x) => ({
    ...x,
    relation: relations.find((y) => y.id === x.relationId)
  }));

  const t = useTranslation('Reports.Table');

  const coverImage = (name: string) =>
    getCdnUrl(`/images/reports/chapters/${name}.jpg`);
  const commentImage = (index: number) =>
    getCdnUrl(`/images/reports/comments/${index % 4 || 4}.jpg`);

  const coverImages = randPerm(
    1,
    61,
    (sections?.length || 0) + items.length
  ).map((x) => coverImage(x.toString()));

  return (
    <Box
      className='report'
      bgcolor='white'
      mx={{
        xs: 0,
        sm: 1,
        md: 2
      }}
      borderRadius='24px'
    >
      <Box
        pb={2}
        borderRadius='24px'
        bgcolor={addAlphaToHex('#000', 2)}
        overflow='hidden'
        sx={{
          overflow: 'hidden',
          '@media print': {
            px: 0,
            '& td, & th': {
              p: '8px',
              fontSize: '14px'
            }
          }
        }}
        className='report'
      >
        <Box
          sx={{
            '@media print': {
              height: '100vh'
            }
          }}
        >
          <TableReportHeader />
          <SectionCover
            isReport
            title={title}
            cover={getCdnUrl('/images/reports/hero.png')}
            icon={customizations?.logo}
          />
        </Box>
        {sectionReports ? (
          <Contents
            hasSummary={!!summary}
            hasComments={false}
            sections={sectionReports}
          />
        ) : items.length > 1 ? (
          <Contents
            hasSummary={!!summary}
            hasComments={comments.length > 0}
            items={items}
          />
        ) : (
          <></>
        )}
        {summary && (
          <Box>
            <SectionCover
              title={t('ExecutiveSummary')}
              cover={getCdnUrl('/images/reports/executive-summary-h.jpg')}
            />
            <Box
              p={{
                xs: 8 / 12,
                sm: centerOf(8 / 12, 4),
                md: 4
              }}
            >
              {summaryTables &&
                summaryTables.map((x, i) => (
                  <TableRenderer
                    key={`summary_${i}`}
                    id={`summary_${i}`}
                    table={x}
                    familiarity={familiarity}
                    items={items}
                  />
                ))}
              <AIReport
                content={summary}
                charts={charts
                  ?.where((x) => x.dataType === ChartDataType.SUMMARY)
                  .map((x) => x.chart)}
              />
            </Box>
          </Box>
        )}
        {sectionReports?.any()
          ? sectionReports?.map((x, i) => {
              const tables =
                sectionsTables &&
                sectionsTables.find((y) => y.id === x.sectionId)?.tables;
              return (
                <Box
                  id={x.sectionId.replace(/[-_]/g, '')}
                  key={`section_${x.sectionId}`}
                >
                  <ChapterCover
                    name={x.section.title}
                    image={coverImages[i]}
                    index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
                  />
                  <Box
                    p={{
                      xs: 8 / 12,
                      sm: centerOf(8 / 12, 4),
                      md: 4
                    }}
                    pb={{
                      xs: 2,
                      sm: 3,
                      md: 4
                    }}
                  >
                    {tables &&
                      tables.map((y) => (
                        <TableRenderer
                          key={`section_${x.sectionId}`}
                          id={`section_${x.sectionId}`}
                          table={y}
                          familiarity={familiarity}
                          items={items.where((z) =>
                            x.section.itemIds.contains(z.id)
                          )}
                        />
                      ))}
                    <AIReport
                      content={x.content}
                      title={x.section.title}
                      charts={charts
                        ?.where(
                          (y) =>
                            y.dataType === ChartDataType.SECTION &&
                            y.dataId === x.sectionId
                        )
                        .map((y) => y.chart)}
                    />
                  </Box>
                </Box>
              );
            })
          : items.map((x, i) => (
              <ReportItem
                key={i}
                {...x}
                index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
                options={options}
                relations={relations}
                tables={tables
                  .filter(
                    (y) =>
                      y.dataType === TableDataType.ITEM && y.dataId === x.id
                  )
                  .flatMap((y) => y.tables)}
                aiReports={aiReports}
                cover={coverImages[i]}
                familiarity={familiarity}
                charts={charts
                  ?.where(
                    (y) =>
                      y.dataType === ChartDataType.ITEM && y.dataId === x.id
                  )
                  .map((y) => y.chart)}
                single={items.length === 1}
              />
            ))}
        <ThemeProvider
          theme={{
            ...theme,
            palette: {
              ...theme.palette,
              primary: {
                ...theme.palette.primary,
                main: customizations?.primaryColor || '#31C7A0',
                light:
                  (customizations?.primaryColor &&
                    lightenHexColor(customizations.primaryColor, 30)) ||
                  '#EBFDF8'
              },
              secondary: {
                ...theme.palette.secondary,
                main: customizations?.secondaryColor || '#31C7A0',
                light:
                  (customizations?.secondaryColor &&
                    lightenHexColor(customizations.secondaryColor, 30)) ||
                  '#EBFDF8'
              }
            }
          }}
        >
          {sectionReports && sectionReports?.any() && (
            <Box id='detailedData'>
              <SectionCover
                title={t('DetailedData')}
                cover={getCdnUrl('/images/reports/detailed-data-h.jpg')}
              />
              <DetailedContents />
              {items.map((x, i) => (
                <ReportItem
                  key={i}
                  {...x}
                  index={`${i + 1}:`}
                  options={options}
                  relations={relations}
                  tables={tables
                    .filter(
                      (y) =>
                        y.dataType === TableDataType.ITEM && y.dataId === x.id
                    )
                    .flatMap((y) => y.tables)}
                  aiReports={aiReports}
                  cover={coverImages[sectionReports!.length + i + 1]}
                  section={t('DetailedData')}
                  familiarity={familiarity}
                  charts={charts
                    ?.where(
                      (y) =>
                        y.dataType === ChartDataType.ITEM && y.dataId === x.id
                    )
                    .map((y) => y.chart)}
                />
              ))}
            </Box>
          )}
          {commentSections &&
            commentSections.map((x, i) => {
              const items = fixedComments.find((y) => y.group === x.id);
              if (!items || items.items.length < 1) {
                return;
              }

              return (
                <Box key={x.id}>
                  <ChapterCover
                    name={x.title}
                    index={`${i + 1}:`}
                    image={commentImage(i + 1)}
                    section={t('Comments')}
                    icon={<ChatBubble color='primary' fontSize='large' />}
                  />
                  <Box
                    id={`comments${i}`}
                    sx={{ breakInside: 'avoid' }}
                    px={{
                      xs: 16 / 12,
                      sm: 2,
                      md: 4
                    }}
                    py={{
                      xs: 2,
                      sm: 5
                    }}
                  >
                    {items.items.map((x, i) => (
                      <CommentBox
                        key={i}
                        index={i}
                        {...x}
                        relation={relations.find((y) => y.id === x.relationId)}
                      />
                    ))}
                  </Box>
                </Box>
              );
            })}
        </ThemeProvider>
      </Box>
      <Box />
    </Box>
  );
};

export default SurveyReportTable;
