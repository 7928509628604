import { useTranslation } from '@/components';
import { addAlphaToHex, fontWeights, forRange } from '@/helpers';
import { Box, Hidden, useTheme } from '@mui/material';
import { ChapterIcon } from '@survey/report/components';

interface IProps {
  items?: TopicItemResponse[];
  sections?: SimpleAISectionReportResponse[];
  hasSummary: boolean;
  hasComments: boolean;
}

const ContentsItem = ({
  index,
  name,
  id
}: {
  index: number;
  name: string;
  id: string;
}) => {
  const theme = useTheme();

  const scrollToContent = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const section = document.getElementById(
      e.currentTarget.href.replace(/^[^#]+#/, '').replace(/[-_]/g, '')
    );
    section?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <Box
      component='a'
      href={`#${id}`}
      onClick={scrollToContent}
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover > div:first-of-type': {
          background: `linear-gradient(to left, ${addAlphaToHex(
            '#fff',
            70
          )} -25%, ${addAlphaToHex(theme.palette.secondary.main, 70)})`
        }
      }}
    >
      <Box
        fontSize={{
          xs: 50,
          md: 80
        }}
        fontWeight={fontWeights.black}
        sx={{
          background: `linear-gradient(to left, ${addAlphaToHex(
            '#fff',
            70
          )} -75%, ${addAlphaToHex('#6D6E71', 70)} 125%)`,
          backgroundClip: 'text !important',
          WebkitTextFillColor: 'transparent'
        }}
      >
        {(index + 1).toFixedLength(2)}
        <ChapterIcon ml={1} />
      </Box>
      <Box
        mr={2}
        fontSize={16}
        fontWeight={fontWeights.semiBold}
        color='#6D6E71'
      >
        {name}
      </Box>
    </Box>
  );
};

const ContentsItemWrapper = ({
  id,
  name,
  itemCount,
  index
}: {
  id: string;
  name: string;
  itemCount: number;
  index: number;
}) => {
  const showEmptyBox = (index: number, after?: boolean) => {
    let emptyBoxes: {
      before: Record<string, number> | number[];
      after: Record<string, number> | number[];
    } = {
      before: [],
      after: []
    };
    switch (itemCount) {
      case 6:
        emptyBoxes = {
          before: [0, 1, 5],
          after: [0, 4, 5]
        };
        break;

      case 7:
        emptyBoxes = {
          before: [0, 1, 5],
          after: [0, 4, 6]
        };
        break;

      case 8:
        emptyBoxes = {
          before: [0, 2],
          after: [1, 2, 7]
        };
        break;

      case 9:
        emptyBoxes = {
          before: [0, 2],
          after: [8]
        };
        break;

      case 10:
        emptyBoxes = {
          before: [0, 3],
          after: { '4': 1, '7': 1, '9': 2 }
        };
        break;

      case 11:
        emptyBoxes = {
          before: [0, 3],
          after: [4, 7, 10]
        };
        break;

      case 12:
        emptyBoxes = {
          before: [0, 3],
          after: [4, 7]
        };
        break;

      case 13:
        emptyBoxes = {
          before: [0, 3],
          after: [4]
        };
        break;

      case 14:
        emptyBoxes = {
          before: [11],
          after: [2]
        };
        break;

      case 15:
        {
          emptyBoxes = {
            before: [],
            after: [2]
          };
        }
        break;

      default:
        break;
    }

    const boxes = emptyBoxes[after ? 'after' : 'before'];
    if (Array.isArray(boxes)) {
      if (boxes.indexOf(index) > -1) {
        return [1];
      }

      return [];
    }

    if (index.toString() in boxes) {
      return forRange(boxes[index]);
    }

    return [];
  };

  return (
    <>
      <Hidden mdDown={window.matchMedia('screen').matches}>
        {showEmptyBox(index).map((_, i) => (
          <Box key={i} flex='auto 1 1' height={822 / 4} />
        ))}
      </Hidden>
      <Box
        flex={{
          xs: '100% 1 1',
          sm: '33% 1 1',
          md: '25% 1 1'
        }}
        height={822 / 4}
        display='flex'
        justifyContent={{
          xs: 'center',
          sm: 'flex-start'
        }}
        alignItems='flex-start'
        width={{
          xs: '100%',
          sm: '33%',
          md: '25%'
        }}
        sx={{
          '@media print': {
            flex: '25% 1 1',
            justifyContent: 'flex-start',
            width: '25%'
          }
        }}
      >
        <ContentsItem index={index} name={name} id={id} key={`item${index}`} />
      </Box>
      <Hidden mdDown={window.matchMedia('screen').matches}>
        {showEmptyBox(index, true).map((_, i) => (
          <Box key={i} flex='auto 1 1' height={822 / 4} />
        ))}
      </Hidden>
    </>
  );
};

const Contents = ({ items, sections, hasSummary, hasComments }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  const chapters = (sections?.map((x) => ({
    id: x.section.id,
    name: x.section.title
  })) ||
    items?.map((x) => ({
      id: x.id,
      name: x.name
    })))!;
  const itemCount =
    chapters.length +
    (hasSummary ? 1 : 0) +
    (hasComments ? 1 : 0) +
    (sections?.any() ? 1 : 0);

  return (
    <Box
      display='flex'
      flexDirection={{
        xs: 'row',
        md: 'column'
      }}
      flexWrap='wrap'
      alignItems='center'
      py={itemCount < 10 ? `${822 / 8}px` : 0}
      px='max(24px, calc((100vw - 1200px) / 2))'
      height={{
        sm: 822
      }}
      sx={{
        breakInside: 'avoid',
        '@media print': {
          flexDirection: 'column',
          height: 822
        }
      }}
    >
      {hasSummary && (
        <ContentsItemWrapper
          key='executiveSummary'
          index={0}
          id='executiveSummary'
          name={t('ExecutiveSummary')}
          itemCount={itemCount}
        />
      )}
      {chapters.map((x, i) => (
        <ContentsItemWrapper
          key={`item${i}`}
          index={i + (hasSummary ? 1 : 0)}
          id={x.id}
          name={x.name}
          itemCount={itemCount}
        />
      ))}
      {hasComments && (
        <ContentsItemWrapper
          key='comments'
          index={chapters.length + (hasSummary ? 1 : 0)}
          id='comments'
          name={t('Comments')}
          itemCount={itemCount}
        />
      )}
      {sections?.any() && (
        <ContentsItemWrapper
          key='detailedData'
          index={chapters.length + (hasComments ? 1 : 0) + (hasSummary ? 1 : 0)}
          id='detailedData'
          name={t('DetailedData')}
          itemCount={itemCount}
        />
      )}
    </Box>
  );
};

export default Contents;
