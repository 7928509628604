import { getLanguage, Redirect } from '@/components';
import { UserRoles } from '@/enums';
import { getAuthCookie } from '@/helpers';
import { ReactElement } from 'react';

interface IProps {
  component: ReactElement;
  unauthorized?: ReactElement;
  forbidden?: ReactElement;
  roles?: UserRoles[];
}

const Authenticate = ({ component, roles, ...props }: IProps) => {
  const lang = getLanguage();
  const unauthorized = props.unauthorized || (
    <Redirect
      to={`/login/?returnUrl=%2F${lang}${encodeURIComponent(
        document.location.pathAndSearch
      )}`}
    />
  );
  const forbidden = props.forbidden || <Redirect to='/' />;

  const user = getAuthCookie();
  if (!user) {
    return unauthorized;
  }

  if (!roles || roles.length === 0) {
    return component;
  }

  if (!roles.any((x) => x === user.role)) {
    return forbidden;
  }

  return component;
};

const AdminAuthenticate = (props: Omit<IProps, 'roles'>) => (
  <Authenticate {...props} roles={[UserRoles.Admin]} />
);

const SuperAdminAuthenticate = (props: Omit<IProps, 'roles'>) => (
  <Authenticate {...props} roles={[UserRoles.SuperAdmin]} />
);

const AdminsAuthenticate = (props: Omit<IProps, 'roles'>) => (
  <Authenticate {...props} roles={[UserRoles.Admin, UserRoles.SuperAdmin]} />
);

const AllAdminsAuthenticate = (props: Omit<IProps, 'roles'>) => (
  <Authenticate
    {...props}
    roles={[UserRoles.LocalAdmin, UserRoles.Admin, UserRoles.SuperAdmin]}
  />
);

const ActionAuthenticate = ({
  children,
  roles
}: {
  children: ReactElement;
  roles: UserRoles[];
}) => {
  const user = getAuthCookie();
  if (!user) {
    return <></>;
  }

  if (!roles || roles.length === 0) {
    return children;
  }

  if (!roles.any((x) => x === user.role)) {
    return <></>;
  }

  return children;
};

const userIsInRole = (...roles: UserRoles[]) => {
  const user = getAuthCookie();
  if (!user) {
    return false;
  }

  if (!roles || roles.length === 0) {
    return true;
  }

  if (!roles.any((x) => x === user.role)) {
    return false;
  }

  return true;
};

export {
  ActionAuthenticate,
  AdminAuthenticate,
  AdminsAuthenticate,
  AllAdminsAuthenticate,
  Authenticate,
  SuperAdminAuthenticate,
  userIsInRole
};
