import { useTranslation } from '@/components';
import { useReportContext } from '@/contexts/report-context';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import {
  Box,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { ItemProgress, StyledTable } from '@survey/report/components';

interface ReportItemRowProps {
  rows: TableAverageItemResponse[];
  relation: TopicRelationDetailedResponse;
  hasBenchmark: boolean;
}

const ReportItemRow = ({
  rows,
  relation,
  hasBenchmark
}: ReportItemRowProps) => {
  rows = rows.filter((x) => x.relationId === relation.id);

  return (
    <TableRow>
      <TableCell component='th'>{relation.relation}</TableCell>
      <TableCell>
        {rows.average((x) => x.value).toFixedString(1, '-')}
      </TableCell>
      {hasBenchmark && (
        <>
          <TableCell>{rows[0]?.benchmark || '-'}</TableCell>
          <TableCell sx={{ p: '4px 8px' }}>
            <ItemProgress
              user={rows.average((x) => x.value! * (x.relation?.impact || 1))}
              other={rows[0]?.benchmark}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

interface IProps {
  id: string;
  rows: TableAverageItemResponse[];
  relations?: TopicRelationResponse[];
}

const TableAverage = ({ id, rows, relations }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');
  const { tableQuestionAverageDisabled } = useReportContext();
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  const total = (rows: TableAverageItemResponse[]) => {
    return rows
      .filter((x) => !x.owner)
      .average(
        (x) => x.value! * (x.relation?.impact || 1),
        (x) => x.relation?.impact || 1
      )
      .toFixedString(1, '-');
  };

  const totalBenchmark = (rows: TableAverageItemResponse[]) => {
    return rows
      .filter((x) => !x.owner)
      .groupBy((x) => ({
        relationId: x.relationId,
        relation: x.relation
      }))
      .map((x) => x.items.average((y) => y.benchmark || 0))
      .average((x) => x)
      .toFixedString(1, '-');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableRow key={'selfRate'}>
          <TableCell component='th' />
          <TableCell component='th' width='1%'>
            {t('Rating')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell
                component='th'
                width='1%'
                sx={{
                  whiteSpace: {
                    sm: 'nowrap'
                  }
                }}
              >
                {t('CohortBenchmark')}
              </TableCell>
              <TableCell
                component='th'
                width='1%'
                sx={{
                  whiteSpace: {
                    sm: 'nowrap'
                  }
                }}
              >
                {t('YoursVSOthers')}
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component='th'>{t('SelfRating')}</TableCell>
          <TableCell>
            {rows
              .filter((x) => x.owner)
              .average((x) => x.value)
              .toFixedString(1, '-')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell>
                {rows
                  .filter((x) => x.owner)
                  .average((x) => x.benchmark || 0)
                  .toFixedString(1, '-')}
              </TableCell>
              <TableCell sx={{ p: '4px 8px' }}>
                <ItemProgress
                  user={rows.filter((x) => x.owner).average((x) => x.value!)}
                  other={rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
        {relations && relations.any() ? (
          relations.map((x) => (
            <ReportItemRow
              key={`item_${id}_relation_${x.id}`}
              rows={rows.filter((y) => !y.owner)}
              relation={x}
              hasBenchmark={hasBenchmark}
            />
          ))
        ) : (
          <ReportItemRow
            key={`item_${id}_relation_other`}
            rows={rows.filter((y) => !y.owner)}
            relation={{
              id: 'other',
              impact: 1,
              minResponses: 0,
              relation: 'Others'
            }}
            hasBenchmark={hasBenchmark}
          />
        )}
      </TableBody>
      {!tableQuestionAverageDisabled && (
        <TableFooter>
          <TableRow
            sx={{
              '&>td': {
                fontSize: '16px',
                fontWeight: fontWeights.bold
              }
            }}
          >
            <TableCell
              sx={{
                boxShadow: 'none !important',
                textAlign: 'center',
                bgcolor: lightenHexColor(theme.palette.secondary.main, 80),
                p: '6px',
                color: getTextColor(
                  lightenHexColor(theme.palette.secondary.main, 80)
                )
              }}
            >
              <Box fontWeight={fontWeights.semiBold}>
                {t('QuestionAverage')}
              </Box>
              <Box
                fontWeight={fontWeights.light}
                fontSize={{
                  xs: '10px',
                  sm: '11px',
                  md: '13px'
                }}
              >
                ({t('ExcludingSelfRating')})
              </Box>
            </TableCell>
            <TableCell>{total(rows)}</TableCell>
            {hasBenchmark && (
              <>
                <TableCell>{totalBenchmark(rows)}</TableCell>
                <TableCell />
              </>
            )}
          </TableRow>
        </TableFooter>
      )}
    </StyledTable>
  );
};

export default TableAverage;
