import CustomizedThemeProvider from '@/components/customized-theme-provider';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import { LocalStorageKeys, TopicPageType } from '@/enums';
import { getQueryString, parseJSON, webClient } from '@/helpers';
import { useAppContext } from '@contexts/app-context';
import { SurveyComment } from '@survey/feedback/components';
import OnePageCheckbox from '@survey/feedback/onePageCheckbox';
import OpenEnded from '@survey/feedback/open-ended';
import WizardMultiOption from '@survey/feedback/wizardMultiOption';
import ProjectDetails from '@survey/project';
import SurveyRelationship from '@survey/relationShip';
import { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

type IProps = {
  code?: string;
  name?: string;
};

const SurveyFeedback = ({ code, name }: IProps) => {
  const { setMainClasses, showToast } = useAppContext();
  const navigate = useNavigate();
  const { state } = useLocation() as {
    state?: SurveyToResponseResponse | TopicSurveyResponse;
  };

  const getIsFeedback = () => !!code && /^[0-9A-Za-z]{6}$/.test(code);

  const getFeedbackGroupId = () => getQueryString('feedbackGroupId');
  const getRelationId = () =>
    !!getFeedbackGroupId() ? getQueryString('relationId') : undefined;
  const getFamiliarityId = () =>
    !!getFeedbackGroupId() ? getQueryString('familiarityId') : undefined;

  const [relationIdSetFromQuery, setRelationIdSetFromQuery] = useState(false);
  const [familiarityIdSetFromQuery, setFamiliarityIdSetFromQuery] =
    useState(false);
  const [hasRelations, setHasRelations] = useState(false);
  const [relationSelected, setRelationSelected] = useState(false);
  const [hasFamiliarity, setHasFamiliarity] = useState(false);
  const [familiaritySelected, setFamiliaritySelected] = useState(false);
  const [commentReceived, setCommentReceived] = useState(false);
  const [submited, setSubmited] = useState(false);
  const [user, setUser] = useState<{ name: string; email: string }>({
    name: '',
    email: ''
  });
  const [topic, setTopic] = useState<
    SurveyToResponseResponse | TopicSurveyResponse
  >(
    state || {
      nickName: '',
      name: '',
      items: [],
      pageType: TopicPageType.SINGLE,
      relations: []
    }
  );
  const [seenDescription, setSeenDescription] = useState(!getIsFeedback());

  const [commentStep, setCommentStep] = useState(
    Number(localStorage.getItem(LocalStorageKeys.FEEDBACK_COMMENT_INDEX) || '0')
  );
  const getProject = () =>
    localStorage.getItem(LocalStorageKeys.SURVEY_PROJECT) || undefined;
  const getCurrentTopic = () =>
    localStorage.getItem(LocalStorageKeys.SURVEY_TOPIC) || undefined;
  const getRelationship = () =>
    localStorage.getItem(LocalStorageKeys.FEEDBACK_RELATION) || undefined;
  const getResponds = () => {
    const responds = localStorage.getItem(LocalStorageKeys.SURVEY_RESPONDS);
    if (!responds || !responds.startsWith('[')) {
      return;
    }

    return JSON.parse(responds) as CreateSurveyRespondItemBody[];
  };
  const getSeniority = () =>
    localStorage.getItem(LocalStorageKeys.SURVEY_SENIORITY) || undefined;

  const loadTopic = async () => {
    try {
      const res = await webClient.api.general.topicForSurvey(
        getIsFeedback(),
        code,
        getProject(),
        getCurrentTopic()
      );
      const topic = res.data.data;
      const hasRelations =
        'relations' in topic && !!topic.relations && topic.relations.length > 0;
      const hasFamiliarity = 'familiarity' in topic;
      let relationSelected = hasRelations && topic.relations!.length === 1;
      if ('relations' in topic && relationSelected) {
        localStorage.setItem(
          LocalStorageKeys.FEEDBACK_RELATION,
          topic.relations![0].id
        );
      }

      const relation = localStorage.getItem(LocalStorageKeys.FEEDBACK_RELATION);
      if (relation) {
        relationSelected = true;
      }
      setHasFamiliarity(hasFamiliarity);
      setHasRelations(hasRelations);
      setRelationSelected(relationSelected);
      setTopic(topic);
    } catch (exp) {
      alert(exp);
    }
  };

  const onSubmitResponds = async (responses: CreateSurveyRespondItemBody[]) => {
    const hasComment =
      ('commentSections' in topic && topic.commentSections?.any()) || false;
    setSubmited(!getIsFeedback() || hasComment);
    localStorage.setItem(
      LocalStorageKeys.SURVEY_RESPONDS,
      JSON.stringify(responses)
    );

    if (!getIsFeedback() || !hasComment) {
      await onSubmit();
    }
  };

  const onSubmit = async () => {
    setCommentReceived(true);

    if (
      'seniorities' in topic &&
      topic.seniorities?.any() &&
      !topic.seniorities.any((x) => x.id === getSeniority())
    ) {
      alert('Seniority is not valid!');
      return;
    }

    const responds = getResponds();
    if (!responds) {
      alert('Responds are empty');
      return;
    }

    try {
      const share =
        localStorage.getItem(LocalStorageKeys.SURVEY_SHARE) === 'true';
      const res = await webClient.post<
        SingleResponse<SurveyStartResponse> | string,
        AxiosResponse<SingleResponse<SurveyStartResponse> | string>,
        CreateSurveyBody | CreateSurveyRespondBody
      >(
        !!code
          ? `/surveys/${code}/feedback/`
          : getProject()
          ? `/surveys/${getProject()}/project/`
          : '/surveys/',
        {
          ...user,
          topic: !getProject() ? getCurrentTopic() : undefined,
          responds,
          comments: parseJSON<CreateSurveyRespondCommentBody[]>(
            localStorage.getItem(LocalStorageKeys.FEEDBACK_COMMENT)
          ),
          feedbackGroupId: getFeedbackGroupId(),
          relationId:
            localStorage.getItem(LocalStorageKeys.FEEDBACK_RELATION) ||
            undefined,
          seniority: getSeniority(),
          familiarityId:
            localStorage.getItem(LocalStorageKeys.FEEDBACK_FAMILIARITY) ||
            undefined,
          reportAccess:
            !!getProject() && !getIsFeedback()
              ? share
                ? 'HAVE_ACCESS'
                : 'NO_ACCESS'
              : undefined
        }
      );
      localStorage.removeItem(LocalStorageKeys.WIZARD_PAGE);
      localStorage.removeItem(LocalStorageKeys.WIZARD_STEP);
      localStorage.removeItem(LocalStorageKeys.WIZARD_ADJECTIVES);
      localStorage.removeItem(LocalStorageKeys.FEEDBACK_RELATION);
      localStorage.removeItem(LocalStorageKeys.FEEDBACK_COMMENT_INDEX);
      localStorage.removeItem(LocalStorageKeys.FEEDBACK_COMMENT);
      localStorage.removeItem(LocalStorageKeys.FEEDBACK_FAMILIARITY);
      localStorage.removeItem(LocalStorageKeys.SURVEY_RESPONDS);
      if (!code) {
        localStorage.setItem(
          LocalStorageKeys.SURVEY_DATA,
          JSON.stringify({
            ...(res.data as SingleResponse<SurveyStartResponse>).data,
            customizations: topic.customizations,
            systemManageDisabled:
              ('systemManageDisabled' in topic && topic.systemManageDisabled) ||
              false
          })
        );
        navigate('/survey/share/', {
          replace: true
        });
      } else {
        localStorage.setItem(
          LocalStorageKeys.SURVEY_THANKS_DATA,
          JSON.stringify({
            topic: res.data as string,
            customizations: topic.customizations
          })
        );
        navigate('/feedback/thanks', {
          replace: true
        });
      }
    } catch (exp) {
      if (exp instanceof AxiosError) {
        const message = exp.response?.data?.message;
        if (message) {
          if (message.indexOf('publish') > -1) {
            navigate('/survey/not-available');
            return;
          } else if (message.indexOf('not found') > -1) {
            navigate('/survey/not-found');
            return;
          }

          await showToast(message, 3e3, 'error');
          return;
        }
      }

      await showToast('Error', 3e3, 'error');
    }
  };

  const init = () => {
    const queryRelationId = getRelationId();
    const queryFamiliarityId = getFamiliarityId();

    if (
      queryRelationId &&
      'relations' in topic &&
      topic.relations.any((x) => x.id === queryRelationId)
    ) {
      localStorage.setItem(LocalStorageKeys.FEEDBACK_RELATION, queryRelationId);
      setRelationIdSetFromQuery(true);
    }

    if (
      queryFamiliarityId &&
      'familiarity' in topic &&
      topic.familiarity?.options.any((x) => x.id === queryFamiliarityId)
    ) {
      localStorage.setItem(
        LocalStorageKeys.FEEDBACK_FAMILIARITY,
        queryFamiliarityId
      );
      setFamiliarityIdSetFromQuery(true);
    }
  };

  useEffect(() => {
    setMainClasses('main-flex');

    const languageSubscriptionId = languageChangeSubscribe(loadTopic);

    if (!getIsFeedback()) {
      const user = {
        name: localStorage.getItem(LocalStorageKeys.USER_NAME),
        email: localStorage.getItem(LocalStorageKeys.USER_EMAIL)
      };

      if (
        (!getCurrentTopic() && !getProject()) ||
        !user ||
        !user.name ||
        !user.email
      ) {
        setTimeout(() => navigate('/'));
      }

      if (!user || !user.name || !user.email) {
        setTimeout(() => navigate('/survey/start/'));
      }

      setUser({
        name: user.name as string,
        email: user.email as string
      });
    }

    if (!state) {
      loadTopic();
    }

    return () => {
      languageChangeUnsubscribe(languageSubscriptionId);
    };
  }, []);

  useEffect(() => {
    if (topic && topic.name) {
      init();
    }
  }, [topic]);

  useEffect(() => {
    if (!('commentSections' in topic) || !topic.commentSections) {
      return;
    }

    if (commentStep >= topic.commentSections.length) {
      onSubmit();
    } else {
      localStorage.setItem(
        LocalStorageKeys.FEEDBACK_COMMENT_INDEX,
        commentStep + ''
      );
    }
  }, [commentStep]);

  const render = () => {
    if (topic.projectDescription && !seenDescription) {
      return (
        <ProjectDetails
          onsubmit={() => setSeenDescription(true)}
          projectDescription={topic.projectDescription}
          customizations={topic.customizations}
          projectName={topic.projectName}
        />
      );
    }

    if (
      ('relations' in topic &&
        hasRelations &&
        !relationSelected &&
        !relationIdSetFromQuery) ||
      ('familiarity' in topic &&
        hasFamiliarity &&
        !familiaritySelected &&
        !familiarityIdSetFromQuery)
    ) {
      return (
        <SurveyRelationship
          relations={topic.relations}
          familiarity={topic.familiarity}
          skipRelations={relationIdSetFromQuery}
          skipFamiliarity={familiarityIdSetFromQuery}
          nickName={topic.nickName || ''}
          next={() => {
            setRelationSelected(true);
            setFamiliaritySelected(true);
          }}
        />
      );
    }

    if (
      getIsFeedback() &&
      (('commentSections' in topic && topic.commentSections?.any()) || false) &&
      commentStep < topic.commentSections.length &&
      submited
    ) {
      return (
        <SurveyComment
          submit={() => setCommentStep(commentStep + 1)}
          comment={topic.commentSections[commentStep]}
          minLength={60}
          last={commentStep === topic.commentSections.length - 1}
        />
      );
    }

    switch (topic.pageType) {
      case TopicPageType.OPEN_ENDED:
        return (
          <OpenEnded
            topic={topic}
            itemId={topic.items[0].id}
            optionId={topic.options![0].id}
            submit={onSubmitResponds}
            code={code}
            project={getProject()}
            topicId={getCurrentTopic()}
            isFeedback={getIsFeedback()}
            name={name}
          />
        );

      case TopicPageType.WIZARD:
      case TopicPageType.WIZARD_SIMPLE:
        return (
          <WizardMultiOption
            project={getProject()}
            user={user}
            topic={topic}
            relationship={getRelationship()}
            simple={topic.pageType === TopicPageType.WIZARD_SIMPLE}
            submit={onSubmitResponds}
            isFeedback={getIsFeedback()}
            code={code}
            name={name}
          />
        );

      case TopicPageType.SINGLE:
      default:
        return (
          <OnePageCheckbox
            project={getProject()}
            user={user}
            topic={topic}
            relationship={getRelationship()}
            submit={onSubmitResponds}
            code={code}
            name={name}
          />
        );
    }
  };

  if (
    topic.customizations &&
    (topic.customizations.primaryColor || topic.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={topic.customizations}>
        {render()}
      </CustomizedThemeProvider>
    );
  }

  return render();
};

export default SurveyFeedback;
