import { Loading, useTranslation } from '@/components';
import { webClient } from '@/helpers';
import { useUserContext } from '@contexts/user-context';
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { Empty, ReportCard, ReportCardGrid } from '@userRoot/components';
import { useEffect, useState } from 'react';

const Reports = () => {
  const { setCurrentPage, showToast, setHeader } = useUserContext();
  const t = useTranslation('PersonalSpace');

  const [reports, setReports] = useState<ReportResponse[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const loadMore = async () => {
    setLoading(true);
    try {
      const res = await webClient.api.reports.list(
        reports.length === 0 ? undefined : reports[reports.length - 1].id,
        {
          loadingHandled: true
        }
      );

      setHasMore(res.data.totalCount > res.data.data.length + reports.length);
      setReports((x) => [...x, ...res.data.data]);
    } catch (exp) {
      showToast('Error!', 3e3, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setHeader('/user/');
    setCurrentPage('reports', 'Reports');
    loadMore();
    // eslint-disable-next-line
  }, []);

  if (loading && reports.length === 0) {
    return <Loading />;
  }

  if (reports && reports.length > 0) {
    return (
      <Box>
        <Grid container spacing={16 / 12} p={16 / 12}>
          {reports.map((x, i) => (
            <ReportCardGrid key={i}>
              <ReportCard
                to={`/reports/survey/${x.referenceId}/`}
                buttonText='SeeReport'
                title={x.createdAt.asDateTime('dd MMMM yyyy')}
                children={
                  (x.survey && [
                    {
                      title: '$TopicName',
                      content: x.survey.name
                    },
                    {
                      title: '$StartDate',
                      content: x.survey.createdAt.asDateTime('dd MMMM yyyy')
                    },
                    {
                      title: '$Status',
                      content: t(
                        x.survey.collectedResponses >= x.survey.neededResponses
                          ? 'Completed'
                          : 'Incomplete'
                      )
                    },
                    {
                      title: 'CollectedResponses',
                      content: x.survey.collectedResponses
                    },
                    {
                      title: 'NeededResponses',
                      content: x.survey.neededResponses
                    }
                  ]) ||
                  'Report'
                }
              />
            </ReportCardGrid>
          ))}
        </Grid>
        {loading && <Loading />}
        {hasMore && (
          <Box textAlign='center'>
            <Button
              variant='contained'
              type='button'
              disabled={loading}
              onClick={loadMore}
            >
              {loading ? <CircularProgress /> : t('$LoadMore')}
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  return <Empty title={t('ThereAreNoReports')} subtitle={t('ReportsHint')} />;
};

export default Reports;
